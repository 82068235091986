<template>
  <div
    class="w-full self-start flex flex-col items-center justify-start font-monrope"
  >
    <div
      class="w-full min-h-[60px] flex flex-wrap justify-center items-center relative"
    >
      <BackButtonComponent />
    </div>

    <div class="flex flex-wrap gap-4 w-full mb-4">
      <TableComponent
        v-if="reports && reports.TRACKING"
        class="w-full max-w-[320px]"
        :tableData="tableData1"
      />
      <TableComponent
        v-if="reports && reports.TRACKING"
        class="w-full max-w-[420px]"
        :tableData="tableData2"
      />
      <TableComponent
        v-if="reports && reports.TRACKING"
        class="w-full max-w-[320px]"
        :tableData="tableData3"
      />
    </div>

    <div
      v-if="reports && reports.TRACKING"
      class="w-full mx-auto mb-4 px-8 py-4 pb-4 flex flex-col justify-between flex-wrap bg-white shadow rounded-lg relative"
    >
      <div class="flex items-center mb-4">
        <div class="text-[#5A5A5F] text-[21px] font-semibold text-center mr-4">
          {{ $t("tracking.Tracking") }}
        </div>
        <SelectControl
          :style="{
            flexDirection: 'row',
            marginBottom: '0',
            width: 'auto',
          }"
          dataKey="trackingMode"
          :data="trackingMode"
          :options="trackingModesOptions"
          @update="handleTrackingMode"
        />

        <template v-if="currentTabIdx === 0 && tabs[currentTabIdx].isEnabled">
          <div
            class="text-[#5A5A5F] text-[21px] font-semibold text-center mx-4"
          >
            {{ $t("tracking.Chart points") }}
          </div>
          <SelectControl
            :style="{
              flexDirection: 'row',
              marginBottom: '0',
              width: 'auto',
            }"
            dataKey="chartPoints"
            :data="chartPoints"
            :options="chartPointsOptions"
            @update="handleChartPoints"
          />
        </template>

        <template
          v-if="
            currentTabIdx === 0 && tabs[currentTabIdx].isEnabled && +jumpCount
          "
        >
          <div
            class="text-[#5A5A5F] text-[21px] font-semibold text-center mx-4"
          >
            {{ $t("tracking.Jumps") }}
          </div>
          <SelectControl
            :style="{
              flexDirection: 'row',
              marginBottom: '0',
              width: 'auto',
            }"
            dataKey="jumps"
            :options="jumpOptions.map((option) => option.label)"
            defaultOption="Select jump"
            @update="selectNewJump"
          />

          <button
            v-if="selectedJump && selectedJump.id !== null"
            class="text-[14px] rounded p-2 bg-[#9A8053] text-white ml-2"
            @click="openAnimationModal"
          >
            {{ $t("tracking.Open animation") }}
          </button>
        </template>
        <InformationCircleIcon
          class="w-6 h-6 ml-auto hover:text-[gray] cursor-pointer"
          @click="isShortcutModalOpen = true"
        />
      </div>
      <div class="w-full flex flex-wrap">
        <div v-if="reports && reports.TRACKING" class="w-full mb-4">
          <div class="block">
            <div class="block">
              <nav
                class="isolate flex divide-x divide-gray-200 rounded-lg shadow overflow-x-auto"
                aria-label="Tabs"
              >
                <button
                  v-for="(tab, tabIdx) in tabs"
                  :key="tab.name"
                  :class="[
                    tabIdx === 0 ? 'rounded-l-lg' : '',
                    tab.isEnabled ? '' : 'hidden',
                    tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '',
                    'group relative flex-1 whitespace-nowrap bg-[#FAF0E6] py-2 px-4 text-center hover:bg-gray-300 focus:z-10 text-[#5A5A5F] text-[18px] font-semibold flex items-center justify-center',
                  ]"
                  :aria-current="tab.current ? 'page' : undefined"
                  @click="handleSwitchTab(tabIdx)"
                >
                  <span>{{ $t(tab.name) }}</span>
                  <span
                    aria-hidden="true"
                    :class="[
                      tab.current ? 'bg-[#9A8053]' : 'bg-indigo-200',
                      'absolute inset-x-0 bottom-0 h-0.5',
                    ]"
                  />
                </button>
              </nav>
            </div>
          </div>
        </div>
        <div
          v-if="currentTabIdx === 0 && tabs[currentTabIdx].isEnabled"
          class="ml-auto flex items-center gap-3 mb-3"
        >
          <div
            v-for="item of multicharts"
            :key="item.scaleID"
            class="flex items-center ml-4 mb-4"
          >
            <input
              type="checkbox"
              class="w-4 h-4 focus:ring-0 focus:ring-offset-0 checked:text-[#5A5A5F] cursor-pointer"
              :style="{ color: item.color }"
              :id="item.title"
              v-model="item.checked"
            />
            <label
              :for="item.title"
              class="text-[14px] font-poppins text-[#5A5A5F] cursor-pointer ml-2 font-bold border-gray-700"
            >
              <span>{{ $t(item.title) }}</span>
            </label>
          </div>
        </div>
      </div>
      <div class="w-full flex flex-wrap justify-between border-2 p-4">
        <HorseTrackingComponent
          :tracking="
            cutValue
              ? currentMappedData.slice(cutValue[0], cutValue[1])
              : currentMappedData
          "
          :lengthPreviousValues="historyLength"
          :fromToCutValue="fromToCutValue"
          :selectedGaits="selectedGaits"
          :trackingMarkersLength="trackingMarkersLength"
          :cutValue="cutValue"
        />
        <div class="flex flex-col items-start w-full lg:max-w-[52%]">
          <MultipleChartComponent
            v-if="
              reports && currentTabIdx === 0 && tabs[currentTabIdx].isEnabled
            "
            :reports="reports"
            :mappedData="
              cutValue
                ? currentMappedData.slice(cutValue[0], cutValue[1])
                : currentMappedData
            "
            :multicharts="multicharts"
            :cutValue="cutValue"
            :secondsByGait="secondsByGait"
            :trackingMarkersLength="trackingMarkersLength"
            :legendWithData="legendWithData"
            @setLegendData="setLegendData"
          />

          <EnergyChartComponent
            v-if="currentTabIdx === 1 && tabs[currentTabIdx].isEnabled"
            :datasets="
              reports.EXPENDITURE_ENERGY_METRICS.raw
                ? reports.EXPENDITURE_ENERGY_METRICS.raw
                : reports.EXPENDITURE_ENERGY_METRICS
            "
            :indexColor="indexColor"
            :cutValue="cutValue"
          />
          <EnergyByAllurChartComponent
            v-if="currentTabIdx === 2 && tabs[currentTabIdx].isEnabled"
            :datasets="
              reports.EXPENDITURE_ENERGY_METRICS.raw
                ? reports.EXPENDITURE_ENERGY_METRICS.raw
                : reports.EXPENDITURE_ENERGY_METRICS
            "
            :indexColor="indexColor"
            :cutValue="cutValue"
          />
          <CenterOfGravityChartComponent
            v-if="currentTabIdx === 3 && tabs[currentTabIdx].isEnabled"
            :tracking="
              cutValue
                ? currentMappedData.slice(cutValue[0], cutValue[1])
                : currentMappedData
            "
            :maxRadius="maxRadius"
            :lengthPreviousValues="historyLength"
          />
        </div>
      </div>

      <div class="flex justify-between items-center gap-8 -mt-4">
        <div class="w-[70%]">
          <div class="mb-4 mx-8">
            <PlayControllerSliderComponent
              :trackingMarkersLength="trackingMarkersLength"
              :cutValue="cutValue"
            />
          </div>
          <div class="mt-20 mb-4 mx-8">
            <CutTrainingComponent
              :trackingMarkersLength="reports.TRACKING.x.length"
              @cutTrackingValue="cutTrackingValue"
              @changeCutValue="changeCutValue"
            />
          </div>
        </div>

        <div class="w-[30%]">
          <PlayControllerButtonsComponent
            :trackingMarkersLength="trackingMarkersLength"
            :cutValue="cutValue"
          />

          <div class="w-full">
            <div class="w-200px flex flex-col mt-2 ml-2">
              <span class="text-[#5A5A5F] text-[18px] font-semibold"
                >{{ $t("tracking.Horse gait history") }} =
                {{ (historyLength / 5).toFixed(0) }}
                {{ $t("tracking.seconds") }}</span
              >
              <Slider
                class="w-[250px] h-1 mt-2 mb-4 cursor-pointer"
                :tooltips="false"
                v-model="historyLength"
                :max="1500"
                :min="50"
              />
            </div>
            <div class="flex flex-wrap mt-1">
              <div
                v-for="item of movements"
                :key="item.id"
                class="flex items-center mx-1"
              >
                <input
                  type="checkbox"
                  class="w-4 h-4 focus:ring-0 focus:ring-offset-0 checked:text-[#5A5A5F] cursor-pointer"
                  :style="{ color: item.backgroundColor }"
                  :id="item.title"
                  v-model="item.checked"
                />
                <label
                  :for="item.title"
                  class="text-[14px] font-poppins text-[#5A5A5F] cursor-pointer ml-2 font-bold border-gray-700"
                >
                  <span>{{ $t(item.title) }}</span>
                </label>
              </div>
            </div>
          </div>

          <div class="flex mt-4">
            <button
              class="p-2 rounded-md border-2 border-[#9A8053] flex items-center font-semibold text-[#9A8053]"
              @click="cutTrackingValue"
            >
              {{ $t("tracking.Crop") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else-if="reports?.TREND && !reports?.TRACKING"
      class="mt-64 text-[#5A5A5F] text-[21px] font-semibold text-center"
    >
      {{ $t("tracking.No data available for this workout") }}
    </div>
  </div>

  <JumpAnimationModal
    xMark
    large
    v-if="isAnimationModalOpen"
    :is-open="isAnimationModalOpen"
    :selectedJump="selectedJump"
    :legendWithData="legendWithData"
    :cutValue="cutValue"
    @handleCancel="closeAnimationModal"
  />

  <ModalComponent
    medium
    confirmHidden
    xMark
    :is-open="isShortcutModalOpen"
    @handleCancel="isShortcutModalOpen = false"
  >
    <template v-slot:title>
      <span class="px-2">{{ $t("shortcuts.title") }}</span>
    </template>
    <template v-slot:content>
      <div
        v-for="item in keyboardShortcutsInfo"
        :key="item.id"
        class="text-[#5A5A5F] px-2"
      >
        <span class="font-bold">{{ $t(item.key) }}</span> -
        {{ $t(item.description) }}
      </div>
    </template>
  </ModalComponent>
</template>
<script>
import PlayControllerSliderComponent from "@/components/training/tracking/PlayControllerSliderComponent.vue";
import BackButtonComponent from "@/components/BackButtonComponent.vue";
import Slider from "@vueform/slider";
import { mapActions, mapGetters, mapMutations } from "vuex";
import EnergyChartComponent from "@/components/training/tracking/trackingComponents/EnergyChartComponent.vue";
import CutTrainingComponent from "@/components/training/tracking/CutTrainingComponent.vue";
import HorseTrackingComponent from "@/components/training/tracking/HorseTrackingComponent.vue";
import CenterOfGravityChartComponent from "@/components/training/tracking/centerOfGravityTracking/CenterOfGravityChartComponent.vue";
import EnergyByAllurChartComponent from "@/components/training/tracking/trackingComponents/EnergyByAllurChartComponent.vue";
import TableComponent from "@/components/UI/TableComponent.vue";
import MultipleChartComponent from "@/components/training/tracking/trackingComponents/multiChart/MultipleChartComponent.vue";
import PlayControllerButtonsComponent from "@/components/training/tracking/PlayControllerButtonsComponent.vue";
import { InformationCircleIcon } from "@heroicons/vue/24/outline";

import {
  formatTimestamp,
  getClass,
  getSecond,
} from "@/components/training/helpers";
import {
  chartPointsOptions,
  keyboardShortcutsInfo,
  movements,
  trackingModeKeys,
  trackingModes,
} from "@/components/training/constants";
import SelectControl from "@/components/UI/SelectControl.vue";
import routeNames from "@/router/routes.js";
import ModalComponent from "@/components/ModalComponent.vue";
import JumpAnimationModal from "@/components/training/tracking/trackingComponents/JumpAnimationModal.vue";

export default {
  name: "TrackingMainComponent",
  components: {
    ModalComponent,
    JumpAnimationModal,
    SelectControl,
    PlayControllerButtonsComponent,
    MultipleChartComponent,
    TableComponent,
    EnergyByAllurChartComponent,
    CenterOfGravityChartComponent,
    HorseTrackingComponent,
    BackButtonComponent,
    PlayControllerSliderComponent,
    Slider,
    EnergyChartComponent,
    CutTrainingComponent,
    InformationCircleIcon,
  },
  data() {
    return {
      movements,
      trackingModes,
      routeNames,
      chartPointsOptions,
      keyboardShortcutsInfo,
      currentTabIdx: 0,
      historyLength: 50,
      multicharts: null,
      cutValue: null,
      fromToCutValue: [],
      isShortcutModalOpen: false,
      selectedJump: null,
      legendWithData: [],
    };
  },
  computed: {
    ...mapGetters([
      "currentTrackIndex",
      "horseTrackingCard",
      "reports",
      "loading",
      "GPSDatafiles",
      "HRDatafiles",
      "selectedChartsList",
      "isTrackStarted",
      "isTrackPaused",
      "trackingMarkersLength",
      "trackingMode",
      "chartPoints",
      "isAnimationModalOpen",
    ]),
    jumpOptions() {
      if (this.reports?.JUMP?.length) {
        return this.reports?.JUMP.map((jump, index) => ({
          id: index + 1,
          label: `Jump ${index + 1} at ${this.formatTimestamp(jump.start)}`,
        }));
      }
      return [];
    },
    trackingModesOptions() {
      return this.GPSDatafiles?.length
        ? this.trackingModes
        : [trackingModeKeys.ML];
    },
    tabs() {
      return [
        {
          name: "tracking.Multichart",
          href: "#",
          current: this.currentTabIdx === 0,
          isEnabled: this.selectedChartsList.includes("Multichart"),
        },
        {
          name: "tracking.Cumulative energy",
          href: "#",
          current: this.currentTabIdx === 1,
          isEnabled: this.selectedChartsList.includes("Cumulative energy"),
        },
        {
          name: "tracking.Expenditure energy",
          href: "#",
          current: this.currentTabIdx === 2,
          isEnabled: this.selectedChartsList.includes("Expenditure energy"),
        },
        {
          name: "tracking.Center of gravity",
          href: "#",
          current: this.currentTabIdx === 3,
          isEnabled: this.selectedChartsList.includes("Center of gravity"),
        },
      ];
    },
    tableData1() {
      return [
        {
          name: this.$t("tracking.Segment distance"),
          value: this.segmentDistance,
          disabled: false,
        },
        {
          name: this.$t("tracking.Segment time"),
          value: this.segmentTime,
          disabled: false,
        },
        {
          name: this.$t("tracking.Segment energy"),
          value: this.segmentEnergy,
          disabled: false,
        },
      ];
    },
    tableData2() {
      return [
        {
          name: this.$t("tracking.Segment average speed"),
          value: this.segmentAvgSpeed,
          disabled: false,
        },
        {
          name: this.$t("tracking.Segment Max speed"),
          value: this.segmentMaxSpeed,
          disabled: false,
        },
        {
          name: this.$t("tracking.Segment Max acceleration"),
          value: this.segmentMaxAcceleration,
          disabled: false,
        },
      ];
    },
    tableData3() {
      return [
        {
          name: this.$t("tracking.Segment Average HR"),
          value: this.segmentAvgHeartRate,
          disabled: Boolean(!this.HRDatafiles),
        },
        {
          name: this.$t("tracking.Segment Max HR"),
          value: this.segmentMaxHeartRate,
          disabled: Boolean(!this.HRDatafiles),
        },
        {
          name: this.$t("tracking.Jump count"),
          value: this.jumpCount,
          disabled: false,
        },
      ];
    },
    mappedMLData() {
      if (!this.reports || !this.reports.TRACKING?.x) return [];
      return this.reports.TRACKING.x.map((i, index) => {
        return {
          x: this.reports.TRACKING.x[index],
          y: this.reports.TRACKING.y[index],
          phi: this.reports.TRACKING.phi
            ? this.convertToDeg(this.reports.TRACKING.phi[index])
            : 0,
          radius: this.reports.TRACKING.radius
            ? this.reports.TRACKING.radius[index]
            : 0,
          ts: this.reports.TRACKING.ts ? this.reports.TRACKING.ts[index] : 0,
          color: this.reports.TRACKING.gait
            ? this.setColor(this.reports.TRACKING.gait[index])
            : "grey",
          id: index,
        };
      });
    },
    mappedGPSData() {
      if (
        !this.GPSDatafiles ||
        !this.GPSDatafiles.length ||
        !this.reports.GPS?.filtered
      ) {
        this.SET_TRACKING_MARKERS_LENGTH(0);
        return [];
      }

      const lastSecond = this.getSecond(
        this.reports.GPS.filtered.ts[this.reports.GPS.filtered.ts.length - 1]
      );

      // Create an array of seconds from 0 to the last second
      const msArray = Array.from({ length: lastSecond * 5 }, (_, i) => i);
      const convertedMsArray = msArray.map((i) => ({
        second: Math.floor(i / 5),
        ts: this.convertSecondsToTs(Math.floor(i / 5)),
      }));

      // Preprocess data to improve performance
      const dataMap = {};
      for (let i = 0; i < this.reports.GPS.filtered.ts.length; i++) {
        const second = this.getSecond(this.reports.GPS.filtered.ts[i]);
        const data = {
          x: this.reports.GPS.filtered.x[i],
          y: this.reports.GPS.filtered.y[i],
          lat: this.GPSDatafiles[i].latitude,
          lng: this.GPSDatafiles[i].longitude,
        };
        if (!dataMap[second]) {
          dataMap[second] = [data];
        } else {
          dataMap[second].push(data);
        }
      }

      // Parse the JUMP data and convert "start" and "end" timestamps to comparable values
      const jumpIntervals = this.reports.JUMP.map((jump) => ({
        start: this.getSecond(jump.start),
        end: this.getSecond(jump.end),
      }));

      const res = msArray.map((index) => {
        const second = Math.floor(index / 5);
        const data = dataMap[second];
        const id = data ? data.findIndex((d) => d) : -1;

        // Determine if the current timestamp falls within any of the jump intervals
        const isInJumpInterval = jumpIntervals.some(
          (interval) =>
            convertedMsArray[index].second >= interval.start &&
            convertedMsArray[index].second <= interval.end
        );

        return {
          x: data ? data[id].x : null,
          y: data ? data[id].y : null,
          lat: data ? data[id].lat : null,
          lng: data ? data[id].lng : null,
          phi: this.reports.TRACKING.phi
            ? this.convertToDeg(this.reports.TRACKING.phi[index])
            : 0,
          radius: this.reports.TRACKING.radius
            ? this.reports.TRACKING.radius[index]
            : 0,
          ts: convertedMsArray[index].ts,
          color: isInJumpInterval
            ? "black" // Set color to black if within jump interval
            : this.reports.TRACKING.gait
            ? this.setColor(this.reports.TRACKING.gait[index])
            : "grey", // Default color based on gait or grey
          gaitType: this.reports.TRACKING.gait[index],
          id: index,
        };
      });

      return res;
    },

    currentMappedData() {
      if (!this.reports || !this.reports.TRACKING?.x) return [];
      else {
        this.SET_TRACKING_MARKERS_LENGTH(this.reports.TRACKING.x.length);
        if (this.trackingMode === trackingModeKeys.ML) {
          return this.mappedMLData;
        } else {
          return this.mappedGPSData;
        }
      }
    },
    indexColor() {
      if (this.cutValue) {
        const newMappedDataArray = this.currentMappedData.slice(
          this.cutValue[0],
          this.cutValue[1]
        );
        const maxValue = newMappedDataArray.length - 1;

        return newMappedDataArray[
          this.currentTrackIndex > maxValue ? maxValue : this.currentTrackIndex
        ].color;
      }

      const maxValue = this.currentMappedData.length - 1;
      return this.currentMappedData[
        this.currentTrackIndex > maxValue ? maxValue : this.currentTrackIndex
      ]?.color;
    },
    selectedGaits() {
      return this.movements.filter((i) => (i.checked = true));
    },
    maxRadius() {
      return this.reports.TRACKING.radius
        ? +Math.max(...this.reports.TRACKING.radius).toFixed(2)
        : 0;
    },
    segmentDistance() {
      if (this.reports && this.GPSDatafiles && this.reports.TRACKING.ts) {
        if (this.trackingMode === trackingModeKeys.GPS) {
          return this.generateGPSSegmentDistance();
        } else if (this.trackingMode === trackingModeKeys.ML) {
          return this.generateMLSegmentDistance();
        }
      } else if (
        this.reports &&
        this.reports.TRACKING.ts &&
        this.reports.SUMMARY
      ) {
        return this.generateMLSegmentDistance();
      }
      return 0;
    },
    segmentTime() {
      if (this.reports && this.reports.TRACKING.ts) {
        const mainTimestamps = this.reports.TRACKING.ts;

        const startTimestamp = this.cutValue
          ? mainTimestamps[this.cutValue[0]]
          : mainTimestamps[0];

        const endTimestamp = this.cutValue
          ? mainTimestamps[this.cutValue[1] - 1]
          : mainTimestamps[mainTimestamps.length - 1];

        const [startHour, startMinute, startSecond] = startTimestamp
          .split(":")
          .map(Number);
        const [endHour, endMinute, endSecond] = endTimestamp
          .split(":")
          .map(Number);

        // Round the milliseconds part to 3 decimal places
        const startMs = Math.round(
          parseFloat(startTimestamp.split(".")[1]) / 1000
        );
        const endMs = Math.round(parseFloat(endTimestamp.split(".")[1]) / 1000);

        const startTimeInSeconds =
          startHour * 3600 + startMinute * 60 + startSecond + startMs / 1000;
        const endTimeInSeconds =
          endHour * 3600 + endMinute * 60 + endSecond + endMs / 1000;

        const timeDifferenceInSeconds = endTimeInSeconds - startTimeInSeconds;

        const hours = Math.floor(timeDifferenceInSeconds / 3600);
        const minutes = Math.floor((timeDifferenceInSeconds % 3600) / 60);
        const seconds = Math.floor(timeDifferenceInSeconds % 60);

        return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
          2,
          "0"
        )}:${String(seconds).padStart(2, "0")}`;
      }
      return 0;
    },
    segmentEnergy() {
      if (!this.reports || !this.reports.TRACKING?.gait) return [];
      else {
        let lastGaitIndex = this.reports.TRACKING.gait.length - 1;
        if (this.cutValue) {
          lastGaitIndex = this.cutValue[1];
        }

        const gaitsObject = this.reports.EXPENDITURE_ENERGY_METRICS.raw
          ? this.reports.EXPENDITURE_ENERGY_METRICS.raw
          : this.reports.EXPENDITURE_ENERGY_METRICS;

        const newIndex = this.findLastNonStayElementIndex(
          this.reports.TRACKING.gait,
          lastGaitIndex
        );
        const lastGait = this.reports.TRACKING.gait[newIndex];
        const lastGaitSecond = Math.floor(newIndex / 5);

        const cumulativeEnergy = lastGait
          ? this.sumOfLastElementsForCumulativeEnergy(
              gaitsObject[lastGait],
              lastGaitSecond
            )
          : 0;
        return (cumulativeEnergy / 1000).toFixed(2);
      }
    },
    secondsByGait() {
      if (!Object.keys(this.reports.TRACKING || {}).length) return [];
      return this.reports.TRACKING.ts.map((i, idx) => {
        return {
          second: this.getSecond(i),
          ts: i,
          gait: this.reports.TRACKING.gait[idx],
          color: this.getGaitColor(this.reports.TRACKING.gait[idx]),
        };
      });
    },
    segmentAvgSpeed() {
      if (this.reports.TRACKING.ts) {
        const [startHour, startMinute, startSecond] = this.segmentTime
          .split(":")
          .map(Number);
        const timeInSeconds = startHour * 3600 + startMinute * 60 + startSecond;

        const avgSpeed = this.segmentDistance / timeInSeconds;
        return Math.round(avgSpeed * 100) / 100;
      }
      return 0;
    },
    segmentMaxSpeed() {
      if (this.GPSDatafiles && this.reports.TRACKING.ts) {
        if (this.trackingMode === trackingModeKeys.GPS) {
          return this.generateGPSMaxSpeed();
        } else {
          return this.generateMLMaxSpeed();
        }
      } else if (this.reports.TRACKING.ts) {
        return this.generateMLMaxSpeed();
      }
      return 0;
    },
    segmentMaxAcceleration() {
      if (
        this.reports &&
        this.reports.SUMMARY &&
        this.reports.SUMMARY_RESAMPLED
      ) {
        let startSliceSecond = 0;
        let endSliceSecond =
          this.getSecond(this.reports.SUMMARY_RESAMPLED.ts.at(-1)) + 1;

        if (this.cutValue) {
          startSliceSecond = Math.round(this.cutValue[0] / 5);
          endSliceSecond = Math.round(this.cutValue[1] / 5);
        }

        const accelerationArray = this.reports.SUMMARY_RESAMPLED.avg_acc.slice(
          startSliceSecond,
          endSliceSecond
        );
        const maxAccValue = Math.max(...accelerationArray);

        return maxAccValue.toFixed(2);
      }
      return 0;
    },
    segmentAvgHeartRate() {
      if (this.reports && this.HRDatafiles && this.HRDatafiles.length) {
        let startSliceSecond = 0;
        let endSliceSecond = this.getSecond(this.HRDatafiles.at(-1).ts) + 1;

        if (this.cutValue) {
          startSliceSecond = Math.round(this.cutValue[0] / 5);
          endSliceSecond = Math.round(this.cutValue[1] / 5);
        }

        const arrayOfHR = this.HRDatafiles.slice(
          startSliceSecond,
          endSliceSecond
        );
        const sumOfHR = arrayOfHR.map((i) => i.hr).reduce((a, b) => a + b, 0);

        return Math.round(sumOfHR / arrayOfHR.length);
      }
      return "-";
    },
    segmentMaxHeartRate() {
      if (this.reports && this.HRDatafiles && this.HRDatafiles.length) {
        let startSliceSecond = 0;
        let endSliceSecond = this.getSecond(this.HRDatafiles.at(-1).ts) + 1;

        if (this.cutValue) {
          startSliceSecond = Math.round(this.cutValue[0] / 5);
          endSliceSecond = Math.round(this.cutValue[1] / 5);
        }

        const HRArray = this.HRDatafiles.map((i) => i.hr).slice(
          startSliceSecond,
          endSliceSecond
        );

        return Math.max(...HRArray);
      }
      return "-";
    },
    jumpCount() {
      if (!this.reports.JUMP_count) return "-";
      let result = 0;
      let startSliceSecond = 0;
      let endSliceSecond =
        this.getSecond(this.reports.SUMMARY_RESAMPLED.ts.at(-1)) + 1;

      if (this.cutValue) {
        startSliceSecond = Math.round(this.cutValue[0] / 5);
        endSliceSecond = Math.round(this.cutValue[1] / 5);
      }
      this.reports.JUMP.forEach((i) => {
        this.getSecond(i.start) >= startSliceSecond &&
          this.getSecond(i.end) <= endSliceSecond &&
          result++;
      });
      return result;
    },
  },

  watch: {
    cutValue(val) {
      const newVal = val
        ? this.currentMappedData.slice(val[0], val[1]).length - 1
        : this.currentMappedData.length - 1;

      this.SET_TRACKING_MARKERS_LENGTH(newVal);
    },
    multicharts: {
      handler: async function (newVal) {
        localStorage.setItem("selected-multicharts", JSON.stringify(newVal));
      },
      deep: true,
    },
    trackingMode() {
      this.movements.map((i) => (i.checked = true));
      this.cutTrackingValue();
    },
  },
  methods: {
    ...mapMutations([
      "SET_REPORTS_DATA",
      "SET_HORSE_LAMENESS",
      "SET_GPS_DATAFILES",
      "SET_HR_DATAFILES",
      "SET_CURRENT_TRACK_INDEX",
      "SET_TRACKING_SPEED",
      "SET_IS_TRACK_STARTED",
      "SET_IS_TRACK_PAUSED",
      "SET_TRACKING_MARKERS_LENGTH",
      "SET_TRACKING_MODE",
      "SET_IS_ANIMATION_MODAL_OPEN",
    ]),
    ...mapActions([
      "playTrack",
      "pauseTrack",
      "handleTrackingMode",
      "handleChartPoints",
    ]),
    getClass,
    getSecond,
    formatTimestamp,

    closeAnimationModal() {
      this.SET_IS_ANIMATION_MODAL_OPEN(false);
      this.SET_CURRENT_TRACK_INDEX(this.currentTrackIndex + 1); // trigger this for track/map hard updating
      document.addEventListener("keydown", this.handleKeyDown);
    },

    openAnimationModal() {
      document.removeEventListener("keydown", this.handleKeyDown);
      this.SET_IS_ANIMATION_MODAL_OPEN(true);
    },

    selectNewJump({ data }) {
      const currentJumpId = this.jumpOptions.find(
        (option) => option.label === data
      )?.id;

      if (currentJumpId) {
        this.selectedJump = {
          ...this.reports.JUMP[currentJumpId - 1],
          id: currentJumpId,
        };

        const totalSeconds = this.getSecond(this.selectedJump.start);
        let newTrackIndex; // need add 1 for going to next second
        if (this.cutValue) {
          newTrackIndex =
            (totalSeconds - Math.floor(this.cutValue[0] / 5)) * 5 - 1;
        } else {
          newTrackIndex = totalSeconds * 5 + 1;
        }

        this.SET_CURRENT_TRACK_INDEX(newTrackIndex);
      }
    },

    setLegendData(newVal) {
      this.legendWithData = newVal;
    },

    getGaitColor(gaitType) {
      switch (gaitType) {
        case "walk":
          return "#1AB0B0";
        case "trot":
          return "#FFCD4B";
        case "gallop":
          return "#F85C7F";
        default:
          return "silver";
      }
    },
    findLastNonStayElementIndex(arr, endIndex) {
      for (let i = endIndex; i >= 0; i--) {
        if (arr[i] !== "stay") {
          return i;
        }
      }
      return -1;
    },
    sumOfLastElementsForCumulativeEnergy(obj, lastGaitSecond) {
      return Object.values(obj).reduce((sum, nestedObj) => {
        if (
          nestedObj.cumulative_energy &&
          Array.isArray(nestedObj.cumulative_energy) &&
          nestedObj.cumulative_energy.length > 0
        ) {
          const { cumulative_energy, ts } = nestedObj;
          for (let i = ts.length - 1; i >= 0; i--) {
            if (this.getSecond(ts[i]) < lastGaitSecond) {
              return sum + cumulative_energy[i];
            }
          }
        }
        return sum;
      }, 0);
    },
    handleSwitchTab(index) {
      this.tabs.map((i) => (i.current = false));
      this.tabs[index].current = true;
      this.currentTabIdx = index;
      // this.multicharts.map((i) => (i.checked = true));
    },
    convertToDeg(rad) {
      const pi = Math.PI;
      return rad * (180 / pi);
    },
    setColor(type) {
      if (type === "stay") return "grey";
      else if (type === "walk") return "#1AB0B0";
      else if (type === "trot") return "#FFCD4B";
      else if (type === "gallop") return "#F85C7F";
    },
    cutTrackingValue() {
      this.SET_CURRENT_TRACK_INDEX(0);
      const cutValueArray = [];
      if (this.fromToCutValue.length) {
        cutValueArray.push(this.fromToCutValue[0] || 0);
        cutValueArray.push(
          this.fromToCutValue[1] || this.currentMappedData.length - 1
        );
      }
      this.cutValue = this.fromToCutValue.length ? cutValueArray : null;
    },
    changeCutValue(val) {
      this.fromToCutValue = [
        this.currentMappedData[val[0]].id ?? 0,
        this.currentMappedData[val[1] - 1]?.id ?? 0,
      ];
    },
    generateGPSMaxSpeed() {
      if (this.reports.GPS?.resampled) {
        let startSliceSecond = 0;
        let endSliceSecond =
          this.getSecond(this.reports.GPS.resampled.ts.at(-1)) + 1;

        if (this.cutValue) {
          startSliceSecond = Math.round(this.cutValue[0] / 5);
          endSliceSecond = Math.round(this.cutValue[1] / 5);
        }

        const speedArray = this.reports.GPS.resampled.speed.slice(
          startSliceSecond,
          endSliceSecond
        );
        const maxSpeedValue = Math.max(...speedArray);

        return maxSpeedValue.toFixed(2);
      }
      return 0;
    },
    generateMLMaxSpeed() {
      if (this.reports.SUMMARY_RESAMPLED) {
        let startSliceSecond = 0;
        let endSliceSecond =
          this.getSecond(this.reports.SUMMARY_RESAMPLED.ts.at(-1)) + 1;

        if (this.cutValue) {
          startSliceSecond = Math.round(this.cutValue[0] / 5);
          endSliceSecond = Math.round(this.cutValue[1] / 5);
        }

        const speedArray = this.reports.SUMMARY_RESAMPLED.avg_vel.slice(
          startSliceSecond,
          endSliceSecond
        );
        const maxSpeedValue = Math.max(...speedArray);

        return maxSpeedValue.toFixed(2);
      }
      return 0;
    },
    generateGPSSegmentDistance() {
      if (this.reports.GPS?.resampled) {
        let startSliceSecond = 0;
        let endSliceSecond =
          this.getSecond(this.reports.GPS.resampled.ts.at(-1)) + 1;

        if (this.cutValue) {
          startSliceSecond = Math.round(this.cutValue[0] / 5);
          endSliceSecond = Math.round(this.cutValue[1] / 5);
        }

        const totalDistance = this.reports.GPS.resampled.distance
          .slice(startSliceSecond, endSliceSecond)
          .reduce((a, b) => a + b, 0);

        return Math.ceil(totalDistance);
      }
      return 0;
    },

    generateMLSegmentDistance() {
      if (this.reports.SUMMARY_RESAMPLED) {
        let startSliceSecond = 0;
        let endSliceSecond =
          this.getSecond(this.reports.SUMMARY_RESAMPLED.ts.at(-1)) + 1;

        if (this.cutValue) {
          startSliceSecond = Math.round(this.cutValue[0] / 5);
          endSliceSecond = Math.round(this.cutValue[1] / 5);
        }

        const totalDistance = this.reports.SUMMARY_RESAMPLED.stride_length
          .slice(startSliceSecond, endSliceSecond)
          .reduce((a, b) => a + b, 0);

        return Math.round(totalDistance);
      }
      return 0;
    },
    convertSecondsToTs(seconds) {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = seconds % 60;

      return [
        String(hours).padStart(2, "0"),
        String(minutes).padStart(2, "0"),
        String(remainingSeconds).padStart(2, "0"),
      ].join(":");
    },

    handleKeyDown(event) {
      const { key, shiftKey } = event;

      const setCurrentTrackIndex = (newValue) => {
        const maxValue = this.reports.TRACKING.x.length - 1;
        this.SET_CURRENT_TRACK_INDEX(Math.min(maxValue, Math.max(0, newValue)));
      };

      switch (key) {
        case "ArrowLeft":
          setCurrentTrackIndex(
            shiftKey ? this.currentTrackIndex - 20 : this.currentTrackIndex - 4
          );
          break;
        case "ArrowRight":
          setCurrentTrackIndex(
            shiftKey ? this.currentTrackIndex + 20 : this.currentTrackIndex + 4
          );
          break;
        case " ":
          event.preventDefault();
          if (this.isTrackStarted && !this.isTrackPaused) {
            this.pauseTrack();
          } else if (!this.isTrackStarted && this.isTrackPaused) {
            this.playTrack();
          }
          break;
        case "0":
          this.SET_CURRENT_TRACK_INDEX(0);
          break;
        case "1":
          this.SET_TRACKING_SPEED(1);
          break;
        case "2":
          this.SET_TRACKING_SPEED(2);
          break;
        case "3":
          this.SET_TRACKING_SPEED(4);
          break;
        case "4":
          this.SET_TRACKING_SPEED(10);
          break;
        case "5":
          this.SET_TRACKING_SPEED(20);
          break;
        default:
          break;
      }
    },
  },
  beforeMount() {
    const defaultMulticharts = [
      {
        title: this.$t("tracking.Speed"),
        scaleId: "y",
        checked: true,
        color: "#F1C40F",
      },
      {
        title: this.$t("tracking.Impulse"),
        scaleId: "y1",
        checked: true,
        color: "#FF6B00FF",
      },
      {
        title: this.$t("tracking.Rhythm"),
        scaleId: "y2",
        checked: true,
        color: "#FF69B4",
      },
      {
        title: this.$t("tracking.Stride length"),
        scaleId: "y3",
        checked: true,
        color: "#6495ED",
      },
      {
        title: this.$t("tracking.Acceleration"),
        scaleId: "y4",
        checked: true,
        color: "#8A2BE2",
      },
      {
        title: this.$t("tracking.Step time"),
        scaleId: "y5",
        checked: true,
        color: "#8FBC8F",
      },
      {
        title: this.$t("tracking.Rhythm deviation"),
        scaleId: "y6",
        checked: true,
        color: "#898121",
      },
      {
        title: this.$t("tracking.Heart rate"),
        scaleId: "y7",
        checked: true,
        color: "#E74C3C",
      },
    ];

    let selectedMulticharts = localStorage.getItem("selected-multicharts");
    let selectedTrackingMode = localStorage.getItem("tracking-mode");
    if (selectedMulticharts && selectedTrackingMode) {
      selectedMulticharts = JSON.parse(selectedMulticharts);
    } else {
      selectedMulticharts = defaultMulticharts;
      selectedTrackingMode = trackingModeKeys.GPS;
      localStorage.setItem(
        "selected-multicharts",
        JSON.stringify(selectedMulticharts)
      );
      localStorage.setItem("tracking-mode", selectedTrackingMode);
    }

    this.multicharts = selectedMulticharts;
    this.handleTrackingMode({ data: selectedTrackingMode });
  },
  async mounted() {
    document.addEventListener("keydown", this.handleKeyDown);
  },
  beforeUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
    this.SET_REPORTS_DATA(null);
    this.SET_HORSE_LAMENESS(null);
    this.SET_GPS_DATAFILES(null);
    this.SET_HR_DATAFILES(null);
    this.SET_CURRENT_TRACK_INDEX(0);
  },
};
</script>

<style lang="scss" scoped>
:deep(#trackingMode) {
  height: 40px;
  width: auto;
  font-size: 14px;
}
:deep(#chartPoints) {
  height: 40px;
  width: auto;
  font-size: 14px;
}
:deep(#jumps) {
  height: 40px;
  width: auto;
  font-size: 14px;
}
</style>
