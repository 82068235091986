<template>
  <TransitionRoot as="template" class="z-50" :show="isOpen">
    <Dialog as="div" class="relative z-1000" @close="closeModal('close')">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center sm:p-0"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all w-full max-h-screen sm:my-8 mx-4 flex flex-col overflow-y-auto"
              :class="{
                'max-w-[450px]': small,
                'w-full md:w-[1216px]': medium,
                'w-full max-w-[2000px]': large,
              }"
            >
              <div
                v-if="xMark"
                class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block"
              >
                <button
                  type="button"
                  class="rounded-md bg-white text-gray-400 hover:text-gray-500"
                  @click="closeModal('close')"
                >
                  <span class="sr-only">Close</span>
                  <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div class="bg-white p-4 flex-grow flex h-full">
                <div class="w-full h-full flex">
                  <TrainingSimulator
                    :selectedJump="selectedJump"
                    :legendWithData="legendWithData"
                    :cutValue="cutValue"
                    class="flex-grow"
                  />
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { XMarkIcon } from "@heroicons/vue/24/outline";
import TrainingSimulator from "@/components/training/tracking/trackingComponents/TrainingSimulator.vue";

export default {
  name: "JumpAnimationModal",
  components: {
    TransitionRoot,
    TransitionChild,
    DialogPanel,
    Dialog,
    XMarkIcon,
    TrainingSimulator,
  },
  props: {
    isOpen: Boolean,
    xMark: Boolean,
    confirmHidden: Boolean,
    isConfirmDisabled: Boolean,
    small: Boolean,
    medium: Boolean,
    large: Boolean,
    selectedJump: Object,
    legendWithData: Array,
    cutValue: Array,
  },
  methods: {
    closeModal(val) {
      val === "close" ? this.$emit("handleCancel") : this.$emit("handleAccept");
    },
  },
};
</script>
