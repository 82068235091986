const movements = [
  {
    id: "stay",
    title: "tracking.Stay",
    backgroundColor: "grey",
    checked: true,
  },
  {
    id: "walk",
    title: "tracking.Walk",
    backgroundColor: "#1AB0B0",
    checked: true,
  },
  {
    id: "trot",
    title: "tracking.Trot",
    backgroundColor: "#FFCD4B",
    checked: true,
  },
  {
    id: "gallop",
    title: "tracking.Gallop",
    backgroundColor: "#F85C7F",
    checked: true,
  },
  {
    id: "jump",
    title: "tracking.Jumps",
    backgroundColor: "black",
    checked: true,
  },
];

const allures = ["walk", "trot", "gallop"];

const chartPointsOptions = ["All", 200, 150, 100, 50];

const keyboardShortcutsInfo = [
  {
    id: "0",
    description: "shortcuts.0",
    key: "0",
  },
  {
    id: "1",
    description: "shortcuts.1",
    key: "1",
  },
  {
    id: "2",
    description: "shortcuts.2",
    key: "2",
  },
  {
    id: "3",
    description: "shortcuts.3",
    key: "3",
  },
  {
    id: "4",
    description: "shortcuts.4",
    key: "4",
  },
  {
    id: "5",
    description: "shortcuts.5",
    key: "5",
  },
  {
    id: "arrowRight",
    description: "shortcuts.ArrowRight",
    key: "→",
  },
  {
    id: "arrowLeft",
    description: "shortcuts.ArrowLeft",
    key: "←",
  },
  {
    id: "Space",
    description: "shortcuts.Space",
    key: "shortcuts.SpaceTitle",
  },
  {
    id: "Alt",
    description: "shortcuts.Alt",
    key: "Alt",
  },
  {
    id: "Shift",
    description: "shortcuts.Shift",
    key: "Shift",
  },
];

const comparisonMultichartInfo = [
  {
    id: "Alt",
    description: "shortcuts.Alt",
    key: "Alt",
  },
  {
    id: "Click",
    description: "shortcuts.Click",
    key: "Click",
  },
];

const trackingModeKeys = {
  GPS: "GPS",
  ML: "ML",
};

const trackingModes = [trackingModeKeys.GPS, trackingModeKeys.ML];

const defaultGaitData = {
  second: 0,
  ts: "00:00:00.0",
  gait: "stay",
  color: "silver",
};

export {
  movements,
  allures,
  trackingModes,
  trackingModeKeys,
  defaultGaitData,
  chartPointsOptions,
  keyboardShortcutsInfo,
  comparisonMultichartInfo,
};
